.p-password-panel {
    padding: $panelContentPadding;
    background: $panelContentBg;
    color: $panelContentTextColor;
    border: $overlayContentBorder;
    box-shadow: $inputOverlayShadow;
    border-radius: $borderRadius;

    .p-password-meter {
        margin-bottom: $inlineSpacing;
    }
}
