.layout-footer {
    margin: 40px 0px;
    color: white;
    div {
        display: flex;
        justify-content: center;
        span {
            margin: 20px;
        }
    }
    a {
        color: white;
    }
}