$primaryColor: #EF913E !default;
$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #ffffff !default;
$highlightBg: $primaryColor;
$highlightTextColor: $primaryTextColor;
@import './theme/_theme';
// $primaryColor: #22bdc9 !default;
// $primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
// $primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
// $primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
// $primaryTextColor: #ffffff !default;
// $highlightBg: $primaryColor;
// $highlightTextColor: $primaryTextColor;
// @import './theme/_theme';