.loader-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	overflow: hidden;
	min-height: 100%;

	.loader {
		display: block;
		position: relative;
		left: 50%;
		top: 50%;
		width: 150px;
		height: 150px;
		margin: -75px 0 0 -75px;
		border-radius: 50%;
		border: 3px solid transparent;
		border-top-color: #f5a72f;
		animation: spin 1.7s linear infinite;
		z-index: 11;

		&:before {
			content: "";
			position: absolute;
			top: 5px;
			left: 5px;
			right: 5px;
			bottom: 5px;
			border-radius: 50%;
			border: 3px solid transparent;
			border-top-color: #0690dd;
			animation: spin-reverse .6s linear infinite;
		}

		&:after {
			content: "";
			position: absolute;
			top: 15px;
			left: 15px;
			right: 15px;
			bottom: 15px;
			border-radius: 50%;
			border: 3px solid transparent;
			border-top-color: #fd5488;
			animation: spin 1s linear infinite;
		}
	}

	.loader-section {
		position: fixed;
		top: 0;
		width: 51%;
		height: 100%;
		background: #222;
		z-index: 10;

		&.section-left {
			left: 0;
		}

		&.section-right {
			right: 0;
		}
	}
}

// Bourbon mixins
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes spin-reverse {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}