.login-body {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 100%;
    padding: 0;
    .login-card {
        align-self: center;
        margin: auto;
        margin-top: calc(100vh / 10);
        width: 90%;
        max-width: 450px;
        min-width: 300px;
        border-radius: 4px;
        background-color: #2e2e2e;
        // box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        img {
            width: 60%;
            display: block;
            margin: auto;
            margin-top: 40px;
        }
        h1 {
            margin: 4px;
            text-align: center;
        }
        p {
            margin: 7px;
            text-align: center;
        }
        .progress-bar {
            position: relative;
            top: 0px;
            width: 100%;
        }
        .mobile-only {
            display: none;
        }
        .login-content {
            padding: 20px 50px;
            h6 {
                color: #313842;
                font-size: 24px;
                font-weight: 400;
                margin: 5px;
            }
            // label {
            //     font-size: 12px;

            //     margin-bottom: 4 px;
            // }
            .login-input {
                margin-top: 2px;
                margin-bottom: 15px;
                input {
                    width: 100%;
                }
            }
            .forgetpassword-container {
                text-align: right;
                margin-bottom: 40px;
                color: #3ca4e7;
            }
            .mobile-bottom {
                display: none;
            }
            button.signin-button {
                width: 100%;
                margin-bottom: 60px;
            }
            .terms {
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .login-body {
        width: 100vw;
        background-position: right;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        .login-card {
            height: auto;
            overflow-y: auto;
            .mobile-only {
                height: auto;
                display: block;
                padding: 30px 30px 0px 30px;
                img {
                    display: block;
                    width: 100%;
                    margin: auto;
                }
                h1,
                p {
                    margin: 4px;
                    text-align: center;
                }
            }
            .login-content {
                padding: 5px 10px;
                h6 {
                    display: none;
                }
                .mobile-bottom {
                    display: block;
                    width: 100%;
                    .signin-link {
                        color: #3ca4e7;
                    }
                }
            }
        }
    }
}