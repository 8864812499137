/* Add your customizations of layout here */

// General layout
body {
  #payfast_div_container {
    background-color: #ffffff;
  }

  // background-color: $primaryColor;
  background-color: #595959;
  // .layout-wrapper .layout-content .layout-breadcrumb {
  //   position: fixed;
  // }
  .layout-wrapper .layout-content .layout-content-container {
    padding: 0px;
    position: relative;
    background-color: $contentBg;
    height: calc(100vh - 54px);
    .router-outlet {
      position: absolute;
      top: 54px;
      padding: 12px;
      height: calc(100vh - 50px - 54px);
      overflow: auto;
      width: 100%;
    }
    // margin-top: 57px;
  }
}

// .p-component {
  // background-color: #6e6e6e !important;
// }

.fc-time-grid,
.fc-time-grid-container {
  max-height: calc(100vh - 470px);
}

.calendar-view .p-card .p-card-content {
  // height: calc(100vh - 210px);
  height: auto;
}

.layout-content {
  padding: 0px;
  display: block;
  .layout-actionbar {
    padding: 0px !important;
    height: 54px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    // position: fixed;
    width: 100%;
    .crummy {
      padding-left: 12px;
      .p-breadcrumb {
        background-color: $actionBarBgColor !important;
        border: none !important;
        padding: 0px !important;
        line-height: 18px;
        ul {
          margin-top: 0px !important;
        }
      }
    }
    .layout-breadcrumb-buttons {
      margin: auto 6px;
      display: inline-block;
      float: none;
      height: 36px;
    }
  }
}

.select-button {
  .p-button {
    overflow: unset !important;
  }

  .label {
    margin-bottom: 20px;
  }
  padding: 8px;
  .p-selectbutton.p-buttonset {
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
    flex-wrap: wrap;
    .p-ripple {
      // width: 160px;
      background-color: #c1c1c1;
      border-radius: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      color: #fff;
      border: none;
    }
    .p-highlight {
      background-color: #3b69c1 !important;
    }
  }
}

// .p-accordion .p-accordion-header .p-accordion-header-link {
//   background-color: $primaryColor !important;
//   color: #fff !important;
// }
// .patient-accordion {
//   .p-panel .p-panel-header {
//     background: #fff !important;
//   }
// }

input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border: 1px solid red;
}

.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 4px;
  font-size: 14px;
}

.layout-menu-content {
  justify-content: flex-start !important;
}

.layout-menu-container app-menu {
  flex: 0 !important;
}

// Page layouts
.title-action {
  margin-bottom: 12px;
  h2 {
    margin: 0px;
  }
  .action-buttons-area {
    button {
      float: right;
    }
  }
}

// For other save buttons sach as pages and quizzes
.header-buttons {
  button {
    margin-bottom: 6px;
    height: 34px;
    width: 100%;
  }
}

.p-button,
.pointer {
  cursor: pointer;
}

.ui-dialog .ui-dialog-content {
  overflow: visible;
}

.p-steps ul {
  flex-wrap: wrap;
}

// .active-menuitem-routerlink {
//     background-color: white !important;
//     color: #0389f2 !important;
// }
.p-inputtext,
.p-dropdown,
.p-calendar,
.p-autocomplete,
.p-multiselect,
.p-textarea,
.p-cascadeselect {
  width: 100%;
}

.p-fileupload {
  white-space: nowrap;
  .p-button {
    margin-top: 0px !important;
  }
}

.first {
  font-size: 16px;
  margin-bottom: 6px;
}

a.p-menuitem-link-active {
  .p-menuitem-icon,
  .p-menuitem-text {
    color: $primaryColor !important;
    font-weight: bold;
  }
}

.pi,
.fa {
  width: 25px;
  text-align: center;
}

.p-rating-icon {
  width: 70px;
}

body .layout-wrapper .topbar .profile .username {
  display: inline-block;
}

.p-toolbar {
  background-color: $primaryColor !important;
  color: white;
  font-weight: bold;
}

//calender month box
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
  height: 30px;
  width: 96px;
  border: 1px solid #ced4da;
  box-shadow: 1px 1px #ced4da;
}
//calender year box
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  height: 30px;
  width: 60px;
  border: 1px solid #ced4da;
  box-shadow: 1px 1px #ced4da;
}


.p-dialog {
  width: 100%;
  max-width: 70vw;
  height: 80vh;
  .p-dialog-content {
    height: 100%;
  }
}

.p-datatable .p-datatable-tbody > tr > td {
  overflow-x: hidden;
}

@media screen and (max-width: 40em) {
  .fc-time-grid,
  .fc-time-grid-container {
    max-height: calc(100vh - 400px);
  }

  .fc-center h2 {
    font-size: 12px;
  }

  .calendar-view .p-card .p-card-content {
    height: calc(100vh - 170px);
  }

  .mydialog,
  .p-dialog {
    width: 100vw;
    max-width: none;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 6px !important;
    img {
      display: none;
    }
  }

  .p-datatable-responsive {
    .p-datatable-thead > tr > th,
    .p-datatable-tfoot > tr > td {
      display: none !important;
    }

    .p-datatable-tbody > tr > td {
      text-align: left !important;
      display: block;
      width: 100%;
      float: left;
      clear: left;
      border: 0 none !important;

      .p-column-title {
        padding: 0.4rem;
        min-width: 30%;
        display: inline-block;
        margin: -0.4em 1em -0.4em -0.4rem;
        font-weight: bold;
      }

      &:last-child {
        border-bottom: 1px solid var(--surface-d);
      }
    }
  }
}

.p-orderlist .p-orderlist-list {
  max-height: none;
}

.modal-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  .modal-content {
    height: calc(100% - 40px);
    width: 100%;
    overflow-y: auto;
  }
  .modal-final-container {
    position:absolute;
    bottom: 0px;
    height: 32px;
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    .final-buttons {
      margin-left: 5px;
    }
  }
}


.final-container {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  .final-buttons {
    margin-left: 5px;
  }
}

.p-hidden-accessible {
  display: none;
}

.p-table {
  td {
    overflow: hidden;
  }
}

.no-buttons {
  .p-orderlist-controls {
    display: none !important;
  }
}

.p-button-icon-only .p-button-label {
  display: none;
}

.p-tabview-panels {
  padding: 0 !important;
}

.p-panel.no-border {
  .p-panel-content {
    border: none;
  }
}

.p-grid {
  margin-left: 0;
  margin-right: 0;
}

label {
  font-weight: bold;
  // margin-top: 6px;
  display: block;
}

.btn-link {
  margin-right: 10px;
  cursor: pointer;
}

.i-btn {
  font-size: 26px !important;
}

.float-right {
  float: right;
}

.small-left-margin {
  margin-left: 6px;
}

.small-top-margin {
  margin-top: 10px;
}

.p-field > label,
.label {
  color: #b7b7b7;
  display: block;
  font-size: 12px !important;
  margin-bottom: 2px !important;
}

.form-array-item {
  background-color: #f2f5ff;
  padding: 16px;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 4px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 12px;
}

.p-tooltip {
  position: absolute;
}

// .p-button-success {
//   background-color: #20b78d !important;
//   border-color: #20b78d !important;
// }

.layout-menu-container .layout-menu li a i {
  width: 20px;
}

@import "swiper/scss";
@import "swiper/scss/effect-flip";
@import "swiper/scss/autoplay"; 

.my-order-list .p-orderlist .p-orderlist-controls {
  display: none!important;
}


.actions-div-global .p-button.p-button-icon-only {
  padding: 0.5rem 1rem!important;
}

.placement .p-badge.p-badge-warning {
  background-color: #FBC02D!important;
  color: #fff!important;
}

.p-button.p-button-outlined {
  background-color: transparent!important;
  color: #EF913E!important;
  border: 1px solid #EF913E!important;
}

.p-button.p-button-text {
  background-color: transparent!important;
  color: #EF913E!important;
  border: transparent!important;
}

.p-inputswitch .p-inputswitch-slider {
  background: #fff!important; 
}

.p-chips .p-chips-multiple-container {
  background-color: #6e6e6e!important;
}

.p-disabled, .p-component:disabled {
  opacity: 0.4!important;
}

.p-organizationchart .p-organizationchart-node-content {
  border: none!important;
}