/* Utils */
.clearfix:after {
	content: " ";
	display: block;
	clear: both;
}

.card {
	border-radius: $borderRadius;
	background: $cardBgColor;
	border: $cardBorder;
	padding: 1.5rem;
	margin-bottom: 1em;
	@include border-radius($borderRadius);

	&:last-child {
		margin-bottom: 0;
	}

	&.card-w-title {
		padding-bottom: 1em;
	}

	.card-header {
		@include flex();
		@include flex-align-center();
		@include flex-justify-between();
	}

	.card-subtitle {
		color: $textSecondaryColor;
		font-weight: 600;
		margin: -1rem 0 1rem 0;
	}
}

.p-toast {
	&.p-toast-top-right,
	&.p-toast-top-left,
	&.p-toast-top-center {
		top: 180px;
	}
}

@-webkit-keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, 20px, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

@-webkit-keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0);
	}
}

@keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0);
	}
}

@keyframes fadeinmask {
	from {
		opacity: 0;
	}
	to {
		opacity: .8;
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
}

.fadeOutUp {
	-webkit-animation-name: fadeOutUp;
	animation-name: fadeOutUp;
}
