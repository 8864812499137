$fontFamily:'Sarabun',
sans-serif;
$fontSize:14px;
$textColor:white;
$textSecondaryColor:#5f6061;
$borderRadius:3px;
$transitionDuration:.3s;
$dividerColor: #dee2e6;
$bodyPadding:25px 50px;
$normalBodyPadding:25px 0px;
$mobileBodyPadding:5px 15px;
$contentBgColor:#6e6e6e;
$responsiveLayoutBreakpoint:991px;
$maskBgColor:#24262e;
$menuBgColor: #232121;
$textColorDark: #fff;
/* horizontal menu */

$horizontalMenuItemActiveBgColor: #ffffff;
$horizontalMenuItemActiveTextColor: #1b1c1e;

/* overlay menu */

$overlayMenuBgColor:#ffffff;
$overlayMenuShadow:0 2px 4px 0 rgba(0, 0, 0, 0.3);
$overlayMenuItemTextColor:#313842;
$overlayMenuItemBorderRadius:3px;

/* actionbar */

$actionItemBgColor:#f8f9fa;
$actionItemIconColor:#757575;
$actionItemHoverBgColor:#e9ecef;

/* mobile menu */

$mobileMenuBgColor:#24262e;
$mobileMenuItemBgColor: rgba(255, 255, 255, 0.2);
$mobileMenuItemHoverBgColor: rgba(255, 255, 255, 0.4);
$mobileMenuItemActiveBgColor:#ffffff !important;
$mobileMenuItemTextColor:rgba(255, 255, 255, 0.72);
$mobileMenuItemActiveTextColor:$primaryColor !important;

/* tooltip */

$tooltipBgColor:#313842;
$tooltipColor:#ffffff;

/* user profile menu */

$userProfileMenuInfoBgColor:#f9fafc;
$userProfileMenuInfoNameTextColor:black;
$userProfileMenuInfoRoleTextColor:#313842;
$userProfileMenuLinkIconColor:#9ba8b8;
$userProfileMenuLinkItemTitleColor:#313842;
$userProfileMenuLinkItemSubTitleColor:#457fca;
//actionbar
$actionBarBgColor:#232121;
$breadcrumbSeparatorColor:rgba(176, 186, 196, 0.4);
$breadcrumbItemTextColor:#313842;
$breadcrumbLinkTextColor:#b0bac4;
$breadcrumbLinkHoverTextColor:#3ca4e7;
//misc
$cardBgColor:#ffffff;
$cardBorder:solid 1px #dfe6ee;

$contentBg: #323030;