/* sarabun-regular - latin */

@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 400;
    src: url("./fonts/sarabun-v6-latin-ext_latin-regular.eot");
    /* IE9 Compat Modes */
    src: local('Sarabun Regular'), local('Sarabun-Regular'), url("./fonts/sarabun-v6-latin-ext_latin-regular.eot#iefix") format('embedded-opentype'), /* IE6-IE8 */
    url("./fonts/sarabun-v6-latin-ext_latin-regular.woff2") format('woff2'), /* Super Modern Browsers */
    url("./fonts/sarabun-v6-latin-ext_latin-regular.woff") format('woff'), /* Modern Browsers */
    url("./fonts/sarabun-v6-latin-ext_latin-regular.ttf") format('truetype'), /* Safari, Android, iOS */
    url("./fonts/sarabun-v6-latin-ext_latin-regular.svg#Sarabun") format('svg');
    /* Legacy iOS */
}


/* sarabun-500 - latin */

@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    src: url("./fonts/sarabun-v6-latin-ext_latin-500.eot");
    /* IE9 Compat Modes */
    src: local('Sarabun Medium'), local('Sarabun-Medium'), url("./fonts/sarabun-v6-latin-ext_latin-500.eot#iefix") format('embedded-opentype'), /* IE6-IE8 */
    url("./fonts/sarabun-v6-latin-ext_latin-500.woff2") format('woff2'), /* Super Modern Browsers */
    url("./fonts/sarabun-v6-latin-ext_latin-500.woff") format('woff'), /* Modern Browsers */
    url("./fonts/sarabun-v6-latin-ext_latin-500.ttf") format('truetype'), /* Safari, Android, iOS */
    url("./fonts/sarabun-v6-latin-ext_latin-500.svg#Sarabun") format('svg');
    /* Legacy iOS */
}


/* sarabun-700 - latin */

@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 700;
    src: url("./fonts/sarabun-v6-latin-ext_latin-700.eot");
    /* IE9 Compat Modes */
    src: local('Sarabun Bold'), local('Sarabun-Bold'), url("./fonts/sarabun-v6-latin-ext_latin-700.eot#iefix") format('embedded-opentype'), /* IE6-IE8 */
    url("./fonts/sarabun-v6-latin-ext_latin-700.woff2") format('woff2'), /* Super Modern Browsers */
    url("./fonts/sarabun-v6-latin-ext_latin-700.woff") format('woff'), /* Modern Browsers */
    url("./fonts/sarabun-v6-latin-ext_latin-700.ttf") format('truetype'), /* Safari, Android, iOS */
    url("./fonts/sarabun-v6-latin-ext_latin-700.svg#Sarabun") format('svg');
    /* Legacy iOS */
}